import React from "react"
import { Layout } from "../components/common/layout"
import { Banner } from "../components/common/banner"
import { SaibaMais } from "../components/common/saiba-mais"
// import { NewsLetter } from "../components/common/news-letter"
import orangeArrow from "../components/assets/img/ic-orange-arrow.png"
import softwareEngineeringImage from "../components/assets/img/pages/quem-somos/software-engineering.png"

const AboutPage = () => (
    <Layout infoPage={{ page: "Quem somos" }}>
        <main className="quem-somos">
            <Banner>
                <div className="content">
                    {/* <h1 className="title bold">
                        SOWX{" "}
                        <span className="smaller bold">
                            <br />
                            TRACE & PROCESS
                        </span>
                    </h1> */}
                    <img
                        src={softwareEngineeringImage}
                        alt="sotware-engineering"
                    />
                    <div className="content-wrapper">
                        <p className="text">
                            Somos uma empresa de Tecnologia da Informação com
                            foco em soluções para o mercado corporativo, atuamos
                            como integradores de soluções disruptivas que
                            agilizam e aprimoram a tomada de decisão.
                            Disponibilizamos tecnologias de ponta e somos
                            especialistas em controle e rastreabilidade total de
                            processos usando Tecnologia RFID (Identificação por
                            Rádio Frequência), IoT (Internet das Coisas) e
                            iDriving – Sistema de Gestão Total de Frota.
                        </p>
                        <a
                            href="http://sowx.com.br/assets/Sowx-institucional-v3.pdf"
                            target="_blank"
                            rel="noreferrer"
                            className="flex-box"
                        >
                            <img
                                className="arrow"
                                src={orangeArrow}
                                alt="arrow"
                                style={{
                                    width: "1.25rem",
                                    height: "1.25rem",
                                    marginRight: "0.5rem",
                                }}
                            />
                            <span
                                className="text"
                                style={{ fontSize: "1.75rem" }}
                            >
                                Clique aqui e baixe o material Institucional
                                completo
                            </span>
                        </a>
                    </div>
                    {/* <p className="text">Especializada em Tecnologia de RFID (Identificação por Rádio Frequência), atua com desenvolvimento de softwares customizados e hardwares de altíssima qualidade e confiabilidade para atender as diversas necessidades de seus clientes.</p>
            <p className="text">Inventário online, mensurar tempo de processos e garantir execução de processos são características básicas de nossa solução. Obter informações confiáveis em tempo real sob situações críticas de processo é um grande desafio para nossos clientes.</p> */}
                </div>
            </Banner>
            <SaibaMais />
            {/* <NewsLetter /> */}
        </main>
    </Layout>
)

export default AboutPage
